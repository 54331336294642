/* Portfolio Section */
.portfolio {
    padding: 0px 20px;
    background: #121212;
    color: #fff;
    text-align: center;
    font-family: 'Comfortaa', sans-serif;
  }
  
  .portfolio h2 {
    font-size: 2.2rem;
    margin-bottom: 10px;
    color: #ffa500;
  }
  
  /* View Controls */
.view-controls {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 30px;
  }
  
  .view-controls button {
    background: #333;
    color: #fff;
    border: none;
    width: 38px; /* Fixed width for a round button */
    height: 38px; /* Fixed height for a round button */
    border-radius: 50%; /* Makes the button circular */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    cursor: pointer;
    transition: transform 0.2s ease, background 0.3s ease;
  }
  
  .view-controls button:hover {
    transform: scale(1.1); /* Slight zoom effect on hover */
    background: #ffa500; /* Changes background color on hover */
  }
  
  
  /* Detailed View */
  .detailed-view {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .project-card {
    background: #222;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease;
  }
  
  .project-card:hover {
    transform: scale(1.05);
  }
  
  .project-card img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
  
  .project-details {
    padding: 15px;
    text-align: left;
  }
  
  .project-details h3 {
    font-size: 1.2rem;
    margin-bottom: 0px;
    color: #ffa500;
  }
  
  .network {
    font-size: 0.9rem;
    color: #bbb;
    font-style: italic;
  }
  
  .network .year {
    color: #4A90E2;
    font-weight: bold;
  }
  
  .network .network-name {
    color: #fff;
  }
  
  
  .project-details .description {
    font-size: 0.95rem;
    color: #ddd;
    padding-top: 8px;
  }
  
  /* List View */
  .list-view {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .list-item {
    display: flex;
    align-items: flex-start;
    gap: 15px;
    padding: 10px;
    background: #222;
    border-radius: 10px;
    transition: transform 0.3s ease;
  }
  
  .list-item:hover {
    transform: scale(1.01);
  }
  
  .thumbnail-list {
    width: 120px;
    aspect-ratio: 16 / 9;
    border-radius: 5px;
    object-fit: cover;
  }
  
  .list-info {
    text-align: left;
  }
  
  .list-info h3 {
    margin: 0;
    color: #ffa500;
  }
  
  .list-info p {
    margin: 5px 0;
    color: #bbb;
  }   
  
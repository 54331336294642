/* Footer Section */
.footer {
    background: #121212;
    color: #fff;
    padding: 20px;
    text-align: center;
    font-family: 'Comfortaa', sans-serif;
    font-size: 0.7rem;
    border-top: 2px solid #ffa500;
    margin-top: 40px;
  }
  
  .footer p {
    margin: 10px 0;
    line-height: 1.6;
  }
  
  .footer strong {
    color: #ffa500;
    font-weight: bold;
  }
  
  .footer a {
    color: #ffa500;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .footer a:hover {
    color: #ff8c00;
  }
  
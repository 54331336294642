/* Import Comfortaa Font */
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;700&display=swap');

/* Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Comfortaa', sans-serif; /* Apply Comfortaa as the default font */
  background-color: #121212; /* Dark background for a clean, modern look */
  color: #fff; /* Default text color */
  line-height: 1.6;
}

a {
  color: #ffa500; /* Accent color for links */
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Section Styles */
section {
  padding: 50px 20px;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  margin-bottom: 10px;
}

p {
  margin-bottom: 10px;
}

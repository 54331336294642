/* Header Section */
.header {
    margin: 0;
    width: 100%;
    overflow: visible;
    padding: 10px 0;
    background-color: #c5c5c5;
  }


  .marquee-logo {
    height: 35px; /* Adjust based on your needs */
    margin: 0 72px;
    transition: transform 0.3s ease-in-out;
  }
  
  .marquee-logo:hover {
    transform: scale(1.05);
    position: relative;
    z-index: 10;
  }

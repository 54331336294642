/* Sticky Menu */
.sticky-menu {
    position: fixed;
    top: -100px; /* Starts off-screen */
    left: 0;
    width: 100%;
    background: linear-gradient(135deg, #1e1e2f, #29294a);
    color: #fff;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);
    transition: top 0.5s ease-in-out, opacity 0.3s ease-in-out;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Comfortaa', sans-serif;
    opacity: 0;
    padding: 0px 0; /* Adjust padding for compact design */
}

/* Menu Appears Gracefully */
.sticky-menu.visible {
    top: 0;
    opacity: 1;
}

/* Sticky Content Layout */
.sticky-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    padding: 12px 20px;
    box-sizing: border-box;
}

/* Availability Section */
.availability {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 1rem;
    font-weight: 500;
    color: #fff;
}

.availability-icon {
    font-size: 1.2rem;
    color: #ffa500;
}

/* Branding Tagline (Only on Large Screens) */
.branding-tagline {
    flex: 1;
    text-align: center;
    font-size: 0.9rem;
    font-weight: 600;
    color: #bbb;
    display: none; /* Hidden on mobile */
}

/* Contact Section */
.contact-section {
    display: flex;
    align-items: center;
    gap: 12px;
}

/* Contact Buttons */
.contact-button {
    background: #ffa500;
    color: #333;
    border: none;
    padding: 8px 14px;
    font-size: 0.9rem;
    font-weight: bold;
    border-radius: 6px;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
    display: flex;
    align-items: center;
    gap: 6px;
    text-decoration: none;
    justify-content: center;
    min-width: 140px;
    max-width: 200px;
}

.contact-button:hover {
    background: #ff8c00;
    transform: scale(1.05);
}

/* Icons */
.icon {
    font-size: 1.2rem;
}

.email-icon {
    color: white;
}

.telegram-icon {
    color: #0088cc;
}

/* Sticky Menu Bottom Line */
.sticky-menu-line {
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, #ffa500, #ff8c00);
}

/* Desktop-Only Enhancements */
@media (min-width: 1024px) {
    .branding-tagline {
        display: block;
    }
}

/* Mobile Layout - Availability Above, Buttons Side by Side */
@media (max-width: 768px) {
    .sticky-content {
        flex-direction: column;
        gap: 6px;
        text-align: center;
    }

    .availability {
        font-size: 0.9rem;
        justify-content: center;
    }

    .contact-section {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 10px;
        width: 100%;
    }

    .contact-button {
        flex: 1;
        padding: 10px 12px;
        font-size: 0.85rem;
        min-width: unset;
    }

    .branding-tagline {
        display: none; /* Hide branding tagline on mobile */
    }
}

/* App.css */

.App {
  text-align: center;
}

header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #333;
}

header .logo {
  font-size: 2.5rem;
  color: #ffa500;
  margin-bottom: 10px;
}

.logos-wrapper img {
  height: 50px;
  object-fit: contain;
}


section {
  padding: 50px 20px;
}

section h1, section h2 {
  margin: 0 0 20px;
}

.hero {
  background: linear-gradient(to bottom, #444, #121212);
  color: #fff;
  padding: 50px 20px;
}

.hero h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.hero h2 {
  font-size: 1.5rem;
  color: #bbb;
}

.thumbnail img {
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.thumbnail img:hover {
  transform: scale(1.05);
}

.thumbnail .year {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 5px 10px;
  font-size: 0.8rem;
  border-radius: 3px;
}

footer {
  text-align: center;
  padding: 20px;
  background: #222;
  color: #bbb;
  margin-top: 50px;
}

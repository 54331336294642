/* Hero Section */
.hero {
    background: linear-gradient(to bottom, #222, #121212);
    color: #fff;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Comfortaa', sans-serif;
    gap: 20px;
  }
  
  /* Hero Content */
  .hero-content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    align-items: center;
    max-width: 1000px;
  }
  
  /* Availability Header */
.availability-header {
    font-size: 1.2rem;
    font-weight: 600;
    color: #ffa500; /* Orange header text */
    text-transform: uppercase;
    margin-bottom: 0px; /* Minimal space below the header */
    animation: pulseText 3s infinite; /* Subtle pulsing animation for text */
  }
  
  /* Pulsing Text Animation */
  @keyframes pulseText {
    0%, 100% {
      opacity: 0.8;
      transform: scale(1);
    }
    50% {
      opacity: 1;
      transform: scale(1.03); /* Slight enlargement */
    }
  }
  
  /* Top Bar */
  .top-bar {
    content: '';
    display: block;
    width: 80%; /* Centered and proportional width */
    height: 0.5px; /* Subtle height */
    margin: 0px auto; /* Space above and below the bar */
    border-radius: 5px; /* Rounded edges for the bar */
  }
  
  /* Dates */
  .availability-body p {
    font-size: 1rem;
    font-weight: 500;
    color: #ddd; /* Light gray for the date text */
    margin: 12px 0; /* Increase vertical separation */
    line-height: 0.6; /* Improve readability */
  }
  
  
  
  
  /* Business Card Section */
  .business-card {
    display: grid;
    grid-template-columns: 25% 75%;
    background: #333;
    border-radius: 15px;
    padding: 20px;
    max-width: 600px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
    gap: 20px;
  }
  
  /* Left Section */
  .left-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  .profile-pic {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    border: 3px solid #ffa500;
  }
  
  .action-button {
    background: #ffa500;
    color: #333;
    border: none;
    padding: 5px 10px;
    font-size: 0.8rem;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 100%;
    text-align: center;
  }
  
  .action-button:hover {
    background: #ff8c00;
    color: #fff;
  }
  
  /* Right Section */
  .right-section {
    padding: 0 10px;
    text-align: left;
  }
  
  .right-section h1 {
    font-size: 1.8rem;
    margin: 0;
    color: #ffa500;
  }
  
  .right-section h2 {
    font-size: 1.2rem;
    margin: 5px 0 10px;
    color: #ddd;
  }
  
  .experience {
    font-size: 1rem;
    margin-bottom: 10px;
    color: #bbb;
  }
  
  .sub-experience {
    font-size: 0.9rem;
    color: #aaa;
  }
  
 /* About Me Section */
.about-me-section {
    background: #333; /* Match the business card background */
    color: #fff; /* Consistent text color */
    padding: 20px 30px; /* Add horizontal padding for better readability */
    margin-top: 20px; /* Space above the section */
    border-radius: 10px; /* Rounded edges for consistency */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5); /* Subtle shadow for depth */
    animation: slideDown 0.5s ease-in-out; /* Smooth slide-down animation */
    font-family: 'Comfortaa', sans-serif; /* Consistent font */
    text-align: left; /* Ensure all text is left-aligned */
    max-width: 1000px; /* Limit width for better layout on larger screens */
    margin-left: auto; /* Center align for larger screens */
    margin-right: auto;
  }
  
  .about-me-section h3 {
    font-size: 1.2rem; /* Slightly larger header */
    color: #ffa500; /* Highlighted header text */
    margin-bottom: 15px; /* Space below the header */
  }
  
  .about-me-section p {
    font-size: 1rem; /* Match paragraph font size */
    line-height: 1.6; /* Increase line spacing for readability */
    color: #ddd; /* Light gray text for better contrast */
    margin-bottom: 15px; /* Space below paragraphs */
  }
  
  .about-me-section ul {
    list-style-type: disc; /* Add bullets to lists */
    margin-left: 20px; /* Indent list items */
    margin-bottom: 15px; /* Space below lists */
  }
  
  .about-me-section ul li {
    font-size: 1rem; /* Match list item font size */
    color: #ddd; /* Light gray text for list items */
    margin-bottom: 5px; /* Space between list items */
  }

  .about-me-section .section-spacing {
    margin: 20px 0; /* Add vertical spacing */
  }
  
  /* Slide-down animation for About Me Section */
  @keyframes slideDown {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  
  /* Slide-down animation */
  @keyframes slideDown {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .about-me-section h3 {
    margin: 0 0 10px;
    color: #ffa500;
  }
  
  .about-me-section p {
    font-size: 1rem;
    line-height: 1.6;
    margin: 0;
  }
  
  /* General Hero Content for Mobile */
@media (max-width: 768px) {
    .hero-content {
      flex-direction: column; /* Stack sections vertically */
      align-items: center;
      gap: 20px;
    }
  
    /* Availability Section: Full Width */
    .availability-section {
      width: 100%; /* Match the width of the container */
      max-width: 600px; /* Same max width as the business card */
    }
  
    /* Business Card Section */
    .business-card {
      width: 100%; /* Full width on smaller screens */
      max-width: 600px; /* Maintain consistent width with availability section */
    }
  }

  /* Modal Section */
.modal-section {
    background: #333;
    color: #fff;
    padding: 20px;
    margin-top: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
    animation: slideDown 0.5s ease-in-out;
  }
  
  .modal-section h3 {
    font-size: 1.2rem;
    color: #ffa500;
    margin-bottom: 15px;
  }
  
  .modal-buttons {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }
  
  .modal-buttons .action-button {
    width: auto;
  }

  /* Contact Me Icon Styling */
.contact-icons {
    display: flex;
    justify-content: space-evenly; /* Even spacing across the section */
    margin-bottom: 10px; /* Increase spacing below icons */
    gap: 10px; /* Add spacing between icons for clarity */
  }
  
  .contact-icons img {
    width: 40px; /* Slightly larger icons for better visibility */
    height: 40px; /* Maintain aspect ratio */
    object-fit: contain;
    margin: 0; /* Remove unnecessary horizontal margin */
    border-radius: 5px; /* Add subtle rounded corners for a polished look */
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; /* Add hover effects */
  }
  
  .contact-icons img:hover {
    transform: scale(1.2); /* Enhance hover effect */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4); /* Add a subtle shadow on hover */
  }
  
  .contact-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px; /* Consistent spacing between items */
    gap: 10px; /* Add spacing between the icon and button */
  }
  
  .contact-item img {
    width: 30px; /* Adjust icon size for uniformity */
    height: 30px; /* Maintain aspect ratio */
    margin-bottom: 10px; /* Ensure proper spacing below the icon */
  }
  
  .contact-item .action-button {
    width: 110px; /* Fixed width for consistent button size */
    padding: 10px;
    font-size: 0.9rem;
    border-radius: 5px;
    background: #ffa500; /* Button background color */
    color: #333; /* Text color */
    text-align: center;
    cursor: pointer;
    border: none;
    transition: background 0.3s ease, transform 0.3s ease;
  }
  
  .contact-item .action-button:hover {
    background: #ff8c00; /* Darker hover color */
    transform: scale(1.05); /* Subtle hover effect */
    color: #fff; /* Change text color on hover */
  }
  
  /* Section Responsiveness */
  @media (max-width: 768px) {
    .contact-icons {
      flex-direction: row; /* Stack icons vertically on smaller screens */
      align-items: center;
      gap: 5px; /* Adjust spacing between items */
    }
  
    .contact-item {
      margin-bottom: 10px; /* Increase spacing for better clarity */
    }
  
    .contact-item .action-button {
      width: 100%; /* Full width for mobile buttons */
    }
  }
  
  
  /* About Me Section - Adjusted Spacing */
.about-me-section p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 20px; /* Add extra space below each paragraph */
    color: #ddd; /* Light gray text for better contrast */
}

/* Add spacing before Education and Skills headers */
.about-me-section h3 {
    font-size: 1.2rem;
    color: #ffa500;
    margin: 20px 0 10px; /* Add 20px space above and 10px below headers */
}

/* Resume Modal: Large Screens */
.modal-resume {
    width: 100%; /* Full width of the parent */
    max-width: calc(600px + 250px); /* Combine widths of business card and availability section */
  }
  
  .pdf-viewer {
    width: 100%;
    height: 500px;
    border: none;
  }
  
  @media (min-width: 1024px) {
    .modal-resume .pdf-viewer {
      height: 650px; /* Taller view on large screens */
    }
  }

  .modal-contact {
    width: 100%; /* Ensure it uses the full width available */
    max-width: 600px; /* Matches the width of the business card window */
    background: #333; /* Keep consistent background color */
    color: #fff; /* Text color */
    padding: 20px; /* Add internal spacing */
    margin: 20px auto; /* Center the modal horizontally */
    border-radius: 10px; /* Add rounded corners */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5); /* Add subtle shadow */
    animation: slideDown 0.5s ease-in-out; /* Smooth animation */
  }
  
  
  
  
  
  
  